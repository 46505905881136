export class FileHelper {

    getExtension(fileName: string) {
        return fileName.split('.').pop();
    }

  /**
   * 파일명(확장자 없이) 가져오기
   * @param filename string 파일명
   */
  getBaseName(filename: string) {
    let base = String(filename).substring(filename.lastIndexOf('/') + 1);
    if (base.lastIndexOf(".") !== -1) {
      base = base.substring(0, base.lastIndexOf("."));
    }

    return base;
  }

  /**
   * 확장자 가져오기
   * @param filename 파일명
   * @return {string}
   */
  getFileExt(filename: string) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
  }
}
