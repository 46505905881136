import {DateHelper} from './date-helper'
import {FileHelper} from "./file-helper";
import {Login} from './login'
import {SignupHelper} from "./signup-helper";
import {StorageHelper} from "./storage-helper";
import {UploadHelper} from "./upload-helper";
import {UrlHelper} from './url-helper'

export class Factory {
  static newInstanceLogin() {
    return new Login();
  }

  static newInstanceUrlHelper() {
    return new UrlHelper();
  }

  static newInstanceDateHelper() {
    return new DateHelper();
  }

  static newInstanceFileHelper() {
    return new FileHelper();
  }

  static newInstanceStorageHelper() {
    return new StorageHelper();
  }

  static newInstanceSignupHelper(){
    return new SignupHelper();
  }

  static newInstanceUploadHelper(){
    return new UploadHelper();
  }
}
