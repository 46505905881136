
export class SignupHelper {

  /**
   * 사업자 등록번호 검증
   * @param regNumber 사업자등록번호
   * @return boolean 검증결과
   */
  validRegNumber(regNumber: string): boolean {
    if ($.trim(regNumber).length === 0) {
      alert("사업자 번호를 먼저 입력해주세요.");

      return false;
    }

    const valueMap = regNumber.replace(/-/gi, '').split('').map(function (item) {
      return parseInt(item, 10);
    });

    valueMap.map(function (item) {
      if (isNaN(item)) {
        return false;
      }
    });

    // tslint:disable-next-line:no-magic-numbers
    if (valueMap.length === 10) {
      return true;
    }

    return false;
  }

  /**
   * 이메일/사업자 번호 중복 체크
   */
  async signupDupCheckByType(type: string, value: string): Promise<number> {

    let result = 0;
    const url = this.getDupCheckURL(type, value);
    await $.getJSON(url, function (data) {
      alert(data.message);
      if (data.result === true) {
        result = 1;
      } else {
        result = 0;
      }
    });

    return result;
  }

  private getDupCheckURL(type: string, value: string): string {
    let url = "";
    switch (type) {
      case "email":
        url = '/checkDupByType?type=email&value=' + value;
        break;
      case "regNumber":
        url = '/checkDupByType?type=regNumber&value=' + value;
        break;
    }

    return url;
  }
}
