// @ts-ignore
// tslint:disable-next-line
if (typeof window == 'undefined') { (global as any).window = {location: {host: {}}}}

export const MEMBER_URL = 'https://member.coolschool.co.kr';
export const DEV_MEMBER_URL = 'https://dev-member.coolschool.co.kr';
export const LOCAL_MEMBER_URL = 'https://local-member.coolschool.co.kr';
export const COOLSCHOOL_URL = 'https://www.coolschool.co.kr';
export const DEV_COOLSCHOOL_URL = 'https://dev.coolschool.co.kr';
export const LOCAL_COOLSCHOOL_URL = 'https://local.coolschool.co.kr';
export const COOL_LOGIN_URL = MEMBER_URL + '/login';
export const DEV_COOL_LOGIN_URL = DEV_MEMBER_URL + '/login';
export const LOCAL_COOL_LOGIN_URL = LOCAL_MEMBER_URL + '/login';
export const COOL_LOGIN_CALLBACK_URL =
    window.location.protocol + '//' + window.location.host + '/callback';
export const COOL_LOGIN_CALLBACK_FOR_SAMSTORY_URL =
    window.location.protocol + '//' + window.location.host + '/login';
export const COOL_LOGIN_CALLBACK_FOR_EMOTICON_URL =
  window.location.protocol + '//' + window.location.host + '/login';
export const SEARCH_URL = '//search.coolschool.co.kr';
export const DEV_SEARCH_URL = '//dev-search.coolschool.co.kr';
export const LOCAL_SEARCH_URL = '//local-search.coolschool.co.kr';
export const COOL_LOGIN_CHECK_URL = `${MEMBER_URL}/loginCheck`;
export const SEARCH_MEMBER_BY_ACCESS_TOKEN = `${SEARCH_URL}/api/cool/_searchAuthMember`;
export const DEV_SEARCH_MEMBER_BY_ACCESS_TOKEN = `${DEV_SEARCH_URL}/api/cool/_searchAuthMember`;
export const LOCAL_SEARCH_MEMBER_BY_ACCESS_TOKEN = `${LOCAL_SEARCH_URL}/api/cool/_searchAuthMember`;
export const DEV_COOL_LOGIN_CHECK_URL = `${DEV_MEMBER_URL}/loginCheck`;
export const LOCAL_COOL_LOGIN_CHECK_URL = `${LOCAL_MEMBER_URL}/loginCheck`;
export const COOL_LOGOUT_URL = `${MEMBER_URL}/logout`;
export const COOLSCHOOL_CALLBACK_URL = 'https://www.coolschool.co.kr/callback';
export const DEV_COOLSCHOOL_CALLBACK_URL = 'https://dev.coolschool.co.kr/callback';
export const LOCAL_COOLSCHOOL_CALLBACK_URL = 'https://local.coolschool.co.kr/callback';
export const COOLFILE_URL = '//coolfile.coolschool.co.kr/api';
export const UPLOAD_FILE_BY_ORIGIN = `${COOLFILE_URL}/uploadFileByOrigin`;
export const COOLMESSENGER_LOGIN_CHECK_URL = 'https://member.coolmessenger.com/loginCheck'
export const DEV_COOLMESSENGER_LOGIN_CHECK_URL = 'https://dev-member.coolmessenger.com/loginCheck'
export const LOCAL_COOLMESSENGER_LOGIN_CHECK_URL = 'https://local-member.coolmessenger.com/loginCheck'
export const COOLMESSENGER_LOGIN_URL = 'https://member.coolmessenger.com/login';
export const DEV_COOLMESSENGER_LOGIN_URL = 'https://dev-member.coolmessenger.com/login';
export const LOCAL_COOLMESSENGER_LOGIN_URL = 'https://local-member.coolmessenger.com/login';
export const COOLMESSENGER_CALLBACK_URL = 'https://school.coolmessenger.com/callback';
export const DEV_COOLMESSENGER_CALLBACK_URL = 'https://dev-school.coolmessenger.com/callback';
export const LOCAL_COOLMESSENGER_CALLBACK_URL = 'https://local-school.coolmessenger.com/callback';

export const enum Env {
    LOCAL = 'local', DEV = 'dev', PROD = 'prod'
}

export const enum ServerName {
    COOLSCHOOL = 'coolschool',
    MEMBER = 'member',
    SEARCH = 'search'
}

export const FN_LOGOUT_URL = (url: string): string => {
    return `${url}/logout?client_id=:client_id`
};

export const FN_LOGOUT_PROC_URL = (url: string): string => {
    return `${url}/logoutProc`
};

export interface LogoutOpts {
    isRestoreLocation: boolean;
    isLogoutProc: boolean;
    logoutProcUrl: string | undefined;
}
