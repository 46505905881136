import * as $ from 'jquery'
import {COOLFILE_URL, UPLOAD_FILE_BY_ORIGIN} from "./constants";
import {FileHelper} from "./file-helper";

export class UploadHelper {

    /**
     * servieType에 따른 사용자 파일 업로드
     * @param FormData 파일 업로드에 필요한 formData
     * @return Object upload된 파일 URL이 저장된 배열 오브젝트
     */
    async uploadFileByOrigin(formData: FormData): Promise<any> {
        const result = {};

        await $.ajax({
            url: UPLOAD_FILE_BY_ORIGIN,
            processData: false,
            contentType: false,
            data: formData,
            type: 'POST',
            success(response) {
                const fileHelper: FileHelper = new FileHelper();
                if (!response.result) {
                    return;
                }
                const dataObj = {};
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < response.data.length; i++) {
                    const data = response.data[i];
                    dataObj[data.originFileName] = data;
                    // tslint:disable-next-line:no-invalid-this
                    result[i] = COOLFILE_URL + '/originHost/' + formData.get("serviceType") + '/' + fileHelper.getBaseName(data.originFileName);
                }
            }
        });

        return result;
    }

    async uploadFileByOriginWithServiceType(formData: FormData, serviceType: any) {
        const result = {};

        if (serviceType === '') {
            serviceType = formData.get("serviceType");
        }
        await $.ajax({
            url: UPLOAD_FILE_BY_ORIGIN,
            processData: false,
            contentType: false,
            data: formData,
            type: 'POST',
            success(response) {
                const fileHelper: FileHelper = new FileHelper();
                if (!response.result) {
                    return;
                }
                const dataObj = {};
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < response.data.length; i++) {
                    const data = response.data[i];
                    dataObj[data.originFileName] = data;
                    // tslint:disable-next-line:no-invalid-this
                    result[i] = COOLFILE_URL + '/originHost/' + serviceType + '/' + fileHelper.getBaseName(data.originFileName);
                }
            }
        });

        return result;
    }
}
