import {Login} from './login'
import {CoolVueCommonParams} from "./model/cool-vue-common-params";

declare const document: any;

export class VueHelper {
    private loginHelper = new Login();

    async settingUserParams(userInfoUrl: string, params: CoolVueCommonParams, callback?: (p: CoolVueCommonParams) => void) {
        // ajax cache 설정
        const isIe = /*@cc_on!@*/false || !!document.documentMode;
        if (isIe) {
            $.ajaxSetup({cache: false});
        }

        if (params && params.isNeedLogin) {
            this.loginHelper.coolLoginCheck(params.envName).then((result: any) => {
                if (!result || !result.coolid) {
                    this.loginHelper.goDefaultCoolLogin(params.clientId, '', params.envName);
                }
            });
        }

        const settings: any = {
            url: userInfoUrl
        };

        let userInfoResp = {} as any;
        if (!params.user) {
            if (params.authorization) {
                settings.headers = {
                    Authorization: params.authorization,
                    ClientId: params.clientId
                };
                userInfoResp = await $.ajax(settings);
                params.isAuth = userInfoResp.result;
                params.user = userInfoResp.result ? userInfoResp.data : {};
            } else {
                params.isAuth = false;
                params.user = {};
            }
        }

        if (params && params.isNeedLogin) {
            if (!params.isAuth) {
                const result = await this.loginHelper.logout(params.clientId, params.envName);
                if (result) {
                    this.loginHelper.goDefaultCoolLogin(params.clientId, '', params.envName);
                }
            }
        }

        if (callback) {
            callback(params);
        }


        return params;
    }

    async settingUserParamsSe(params: CoolVueCommonParams, callback?: (p: CoolVueCommonParams) => void): Promise<any> {
        // ajax cache 설정
        const isIe = /*@cc_on!@*/false || !!document.documentMode;
        if (isIe) {
            $.ajaxSetup({cache: false});
        }

        try {
            if (params && params.isNeedLogin && !params.user) {
                const response = await this.loginHelper.coolLoginCheckByAccessToken(params.envName, params.authorization);
                params.isAuth = response.result;
                params.user = response.result ? response.data : {};
            }
        } catch (e) {
            params.isAuth = false;
            params.user = {};
        }

        if (params && params.isNeedLogin && !params.isAuth && !params.user) {
            if(console) {
                console.warn('401 Unauthorized');
            }
            // this.loginHelper.goDefaultCoolLogin(params.clientId, '', params.envName);
        }

        if (params && params.user && !params.authorization) {
            if(console) {
                console.warn('user 정보가 있으면 authorization(accessToken) 필수');
            }

            return;
        }

        if (callback) {
            callback(params);
        }

        return params;
    }

}
