export class ValidationHelper {

    /**
     *
     * @param element - validation 하고 싶은 form
     * @param ignoreEl - 무시하고 싶은 element
     * @param callback - 완료 후 콜백하고 싶은 함수
     */
    constructor(element: string, ignoreEl?: string, callback?: any) {
        ($(element) as any).validate({
            ignore: `${ignoreEl}`,
            submitHandler: () => {
                if (callback) {
                    callback();
                }
            }
        });

        $.extend(($ as any).validator.messages, {
            required: "필수 항목입니다.",
            remote: "항목을 수정하세요.",
            email: "유효하지 않은 E-Mail주소입니다.",
            url: "유효하지 않은 URL입니다.",
            date: "올바른 날짜를 입력하세요.",
            dateISO: "올바른 날짜(ISO)를 입력하세요.",
            number: "유효한 숫자가 아닙니다.",
            digits: "숫자만 입력 가능합니다.",
            creditcard: "신용카드 번호가 바르지 않습니다.",
            equalTo: "같은 값을 다시 입력하세요.",
            extension: "올바른 확장자가 아닙니다.",
            maxlength: ($ as any).validator.format("{0}자를 넘을 수 없습니다. "),
            minlength: ($ as any).validator.format("{0}자 이상 입력하세요."),
            rangelength: ($ as any).validator.format("문자 길이가 {0} 에서 {1} 사이의 값을 입력하세요."),
            range: ($ as any).validator.format("{0} 에서 {1} 사이의 값을 입력하세요."),
            max: ($ as any).validator.format("{0} 이하의 값을 입력하세요."),
            min: ($ as any).validator.format("{0} 이상의 값을 입력하세요.")
        });

        ($ as any).validator.addMethod("whitespace", (value: any): any => {
                let result = true;
                if ((value && $.trim(value).length === 0) || value.indexOf(' ') !== -1) {
                    result = false;
                }

                return result;
            },
            "띄어쓰기가 들어갈 수 없습니다."
        );
    }

}
