declare const kakao: any;

export class KakaoMapHelper {

    private mapOpts: any;

    constructor( mapOpts?: any ) {
        mapOpts = mapOpts || {};
        this.mapOpts = mapOpts || {};
        if (!mapOpts.element) {
            this.mapOpts.element = 'map';
        }
        if (!mapOpts.latitude || !mapOpts.longitude) {
            // ie11에서 반응을 못해서 주석처리
            this.mapOpts.latitude = '37.56455490632751';
            this.mapOpts.longitude = '126.9756149637474';
            /*if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.mapOpts.latitude = position.coords.latitude;
                    this.mapOpts.longitude = position.coords.longitude;
                }, () => {
                    // 내 위치 안나오면 서울시청 좌표
                    this.mapOpts.latitude = '37.56455490632751';
                    this.mapOpts.longitude = '126.9756149637474';
                });
            } else {
                // 내 위치 안나오면 서울시청 좌표
                this.mapOpts.latitude = '37.56455490632751';
                this.mapOpts.longitude = '126.9756149637474';
            }*/
        }
        if (!mapOpts.level) {
            this.mapOpts.level = '4';
        }
    }

    latLng(latitude: any, longitude: any) {
        return new kakao.maps.LatLng(latitude, longitude);
    }

    map() {
        const mapContainer = document.getElementById(this.mapOpts.element); // 지도를 표시할 div
        if (mapContainer) {
            const mapOption = {
                center: this.latLng(this.mapOpts.latitude, this.mapOpts.longitude), // 지도의 중심좌표
                level: this.mapOpts.level // 지도의 확대 레벨
            };
            this.mapOpts.map = new kakao.maps.Map(mapContainer, mapOption);

            this.mapOpts.map.relayout();
            // 지도 중심을 변경한다.
            this.mapOpts.map.setCenter(mapOption.center);
            // 마커를 결과값으로 받은 위치로 옮긴다.
        }
    }

    marker() {
        const latLng = this.latLng(this.mapOpts.latitude, this.mapOpts.longitude);
        const marker = new kakao.maps.Marker({
            'position': latLng,
            'map': this.mapOpts.map
        });
        marker.setPosition(latLng);
        marker.setMap(this.mapOpts.map);
    }

    geocoderAddressSearch(address: string, callback?: any) {
        return new kakao.maps.services.Geocoder().addressSearch(address, callback);
    }

    geocoder() {
        return new kakao.maps.services.Geocoder();
    };

    /*mapClick() {
        kakao.maps.event.addListener(this.mapOpts.map, 'click', (mouseEvent: any) => {
            const latlng = mouseEvent.latLng;
            this.mapOpts.latitude = latlng.Ga;
            this.mapOpts.longitude = latlng.Ha;
            // 마커 위치를 클릭한 위치로 옮깁니다
            this.marker();
        });
    }*/

}
